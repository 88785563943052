<template>
  <div class="countdown" :class="type">
    <div class="countdown-item">
      <span class="countdown-value days">{{ days }}</span>
      <span class="countdown-label">{{ $t("COMPONENTS.COUNTDOWN.@DAYS") }}</span>
    </div>
    <div class="countdown-item hours">
      <span class="countdown-value">{{ hours }}</span>
      <span class="countdown-label">{{ $t("COMPONENTS.COUNTDOWN.@HOURS") }}</span>
    </div>
    <div class="countdown-item minutes">
      <span class="countdown-value">{{ minutes }}</span>
      <span class="countdown-label">{{ $t("COMPONENTS.COUNTDOWN.@MINUTES") }}</span>
    </div>
    <div class="countdown-item seconds">
      <span class="countdown-value">{{ seconds }}</span>
      <span class="countdown-label">{{ $t("COMPONENTS.COUNTDOWN.@SECONDS") }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
const { target, type } = defineProps<{
  target: string;
  type?: string;
}>();
const targetDate = new Date(target);

const days = ref<number>(0);
const hours = ref<number>(0);
const minutes = ref<number>(0);
const seconds = ref<number>(0);

let countdownInterval: ReturnType<typeof setInterval> | null = null;

const emit = defineEmits(["ready"]);

onBeforeMount(() => {
  countdownInterval = setInterval(updateCountdown, 1000);
});
onBeforeUnmount(() => {
  if (countdownInterval) {
    clearInterval(countdownInterval);
  }
});
onMounted(() => {
  setTimeout(() => {
    emit("ready");
  }, 800);
});
function updateCountdown() {
  const now = new Date().getTime();
  const distance = targetDate.getTime() - now;

  days.value = Math.floor(distance / (1000 * 60 * 60 * 24));
  hours.value = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  minutes.value = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  seconds.value = Math.floor((distance % (1000 * 60)) / 1000);
}
</script>

<style lang="scss" scoped>
.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1rem;
  }

  &-value {
    font-weight: bold;
    color: var(--showcase-color-primary-200);
    font-weight: 800;
    font-size: 25px;
  }

  .countdown-label {
    font-size: 12px;
    color: #fff;
    font-weight: 200;
  }
  &.banner-jo {
    .countdown-label {
      font-size: 12px;
      color: #fff;
      font-weight: 200;
      display: none;
      @include mq(desktop) {
        display: block;
      }
    }
    .countdown-item {
      margin: 0 0.5rem;
      &.seconds {
        display: none;
        @include mq(desktop) {
          display: flex;
        }
      }
      .countdown-label {
        display: flex;
        font-size: 9px;
        @include mq(desktop) {
          font-size: 12px;
        }
      }
      .countdown-value {
        font-size: 18px;
        @include mq(desktop) {
          font-size: 25px;
        }
      }
      display: flex;
      flex-wrap: nowrap;
    }
  }
}
</style>
